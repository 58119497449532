import { CookieService } from '@/services/cookie';
import jwt_decode from 'jwt-decode';
import store from '@/store';

const deleteLoginCookies = () => {
    CookieService.deleteCookie('refresh_token');
    CookieService.deleteCookie('access_token');
    CookieService.deleteCookie('userId');
};

const setLoginCookies = (data) => {
    const { exp: refreshExpires } = jwt_decode(data.refresh_token);
    const { exp: accessExpires } = jwt_decode(data.access_token);
    CookieService.setCookie('refresh_token', data.refresh_token, refreshExpires);
    CookieService.setCookie('access_token', data.access_token, accessExpires);
};

const deleteLoginSession = () => {
    sessionStorage.removeItem('userInfo');
    sessionStorage.removeItem('checkout');
};

const userLogout = () => {
    deleteLoginCookies();
    deleteLoginSession();
    store.dispatch('user_info_store/axn_setEmptyUserInfo');
};

const sessionServiceDomain = {
    setLoginCookies,
    deleteLoginCookies,
    deleteLoginSession,
    userLogout,
};

export { sessionServiceDomain };