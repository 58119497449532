<template>
  <div class="activation-expired-view">
    <p class="activation-expired-view__title">
      {{ errorMessage }}
    </p>
    <div
      class="simple-link"
      @click.self="goToHome"
    >
      {{ $t('loginapp.backToLogin') }}
    </div>
    <a
      class="simple-link"
      :href="`${unexpectedErrorHelpUrl}?source=account_login`"
    >
      {{ $t('loginapp.support') }}
    </a>
  </div>
</template>

<script>
import { mParticleMetric } from '@/metrics';
import { LOGIN_ACTIONS_CONSTANTS } from '@/domain/login';
import { ROUTER_PATHS } from '@/router/paths';
import { sessionServiceDomain } from '@/domain/session';
import { URL_CONSTANTS } from '@/externalURLs';

export default {
    data() {
        return {
            unexpectedErrorHelpUrl: URL_CONSTANTS.UNEXPECTED_ERROR_HELP,
        };
    },
    computed: {
        errorMessage() {
            if(this.isUndefinedError) {
                return this.$t('loginapp.error_registering');
            }

            return this.$t(`loginapp.${this.$route.query.errorCode}`);
        },
        isUndefinedError() {
            return typeof this.$route.query.errorCode === LOGIN_ACTIONS_CONSTANTS.UNDEFINED;
        },
    },
    mounted() {
        sessionServiceDomain.deleteLoginCookies();
        sessionServiceDomain.deleteLoginSession();
        this.$store.dispatch('user_info_store/axn_setEmptyUserInfo');
        mParticleMetric.track(mParticleMetric.EVENTS.ACTIVATION_EXPIRED_VIEW);
    },
    methods: {
        goToHome() {
            sessionServiceDomain.userLogout();
            const action = sessionStorage.getItem('action');

            this.$router.push({
                path: ROUTER_PATHS.HOME,
                query: {
                    action,
                },
            });
        },
    },
};
</script>

<style src="./ActivationExpiredView.scss" lang="scss"></style>
